type Query = {
  path: string
  catalogId?: string
  catalogType?: string
  catalogPath?: string
}

export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useAppLogger('middleware/bloomreach.ts')
  let path = to.fullPath.replace(/\/$/, '').replace(/#.*(?=\?)|#.*$/, '') // Remove trailing slash and anchored hashes

  const query: Query = {
    path,
  }

  let catalogData = null
  if (to.fullPath?.split('?')?.shift()?.includes('.html')) {
    const data = to.meta.data
    query.catalogId = data.id
    query.catalogType = data.type
    query.catalogPath = data.path
    catalogData = data

    // If there's more than one facet, fallback to the category page
    if (to.meta.data?.facets?.length > 1) {
      path = path.replace(/\/[^\/]*$/, '')
      query.path = path
    }
  }

  let searchRouteData = null

  const cacheKey = `bloomreach_page_${path}`
  //TODO if queryParam contains token or referer contains bloomreach -> do not cache the page
  const { data: page } = useNuxtData(cacheKey)

  if (to.path.includes('/catalogsearch')) {
    searchRouteData = {
      ...page.value?.catalog,
      type: 'SEARCH',
      relative_url: 'catalogsearch/result',
      query: JSON.parse(JSON.stringify(to.query)),
    }
  }
  if (!page.value) {
    logger.info('fetching data', cacheKey)

    page.value = await $fetch('/api/bloomreach/page', { query }).catch((e) => {
      to.meta.data = {
        pageType: 'ERROR',
        statusCode: e.statusCode,
        statusMessage: e.statusMessage,
      }
      to.meta.type = 'ERROR'
    })
  } else {
    logger.info('using cached data', cacheKey)
  }

  to.meta.page = page.value
  to.meta.routeData = searchRouteData || catalogData || {}
  to.meta.path = catalogData?.path || ''

  // If the type hasn't been set (so not a catalog page), set it based on the route
  if (!to.meta.type) {
    to.meta.type = to.path.includes('/catalogsearch') ? 'SEARCH' : page.value?.pageType || 'BLOOMREACH'
  }
})
